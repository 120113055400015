@import url('https://marketing-assets.vercel.app/Brice.css');
@import url('https://marketing-assets.vercel.app/Beausite.css');

html,
body {
  font-family: 'Beausite', sans-serif !important;
  font-weight: 400;
  overflow: hidden;
  height: 100%;
  overscroll-behavior: none; /* disable pull-to-refresh */
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 5px;
}
::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

body.redirect {
  $default-background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.8));
  $background: var(--bg-brand, $default-background);

  background: $background;

  @media screen and (max-width: 750px) {
    background: none;

    &.show-background #just-form-header::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -1;
      width: 100%;
      height: 100%;
      opacity: 0.6;
      background: $background;
    }
  }
}

* {
  font-family: 'Beausite', sans-serif !important;
}

.pin-validation {
  font-family: 'Brice', sans-serif !important;
  font-weight: 900;
  color: #1c1c1c;
  font-size: 1.5rem;
  font-style: normal;
  line-height: 1.5rem;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: inherit !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  border-color: #e0e0e0 !important;
}

input:autofill,
input:-webkit-autofill-strong-password,
input:-webkit-autofill-strong-password-viewable,
input:-webkit-autofill-and-obscured {
  color: inherit !important;
  border-color: #e0e0e0 !important;
}

#just-form-layout {
  --transition-speed: 0.4s;
  --effect: ease-in-out;
  position: fixed;
  transition: transform var(--transition-speed) var(--effect);
}

#__next {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  position: relative;
}

/* mobile and very small screens portrait and landscape mode */
@media screen and (max-width: 750px), screen and (max-height: 450px) {
  #just-form-layout {
    width: 100%;
    height: 100%;
    z-index: 2147483645;
    &.inIframe {
      transform: translateY(100%);
      &.slideIn {
        transform: translateY(0%) !important;
      }
    }
  }
}

/* Desktop medium */
@media screen and (min-width: 750px) and (min-height: 450px) {
  #just-form-layout {
    width: 763px;
    height: 706px;
    border-radius: 30px;
    overflow: hidden;
  }
}

/* Desktop fullsize */
@media screen and (min-width: 900px) and (min-height: 450px) {
  #just-form-layout {
    width: 909px;
    height: 706px;
    border-radius: 30px;
    overflow: hidden;
  }
}

#chakra-toast-manager-top {
  z-index: 99999999999 !important;
}

/* Applying the animation to the div */
.rotate {
  transition: transform 0.3s ease;
}
.rotate90 {
  transform: rotate(90deg);
}

/* Loader text animation */
@keyframes l1 {
  to {
    opacity: 0;
  }
}
.loader-text-opacity {
  animation: l1 1s linear infinite alternate;
}
